<template>
  <div class="circleCygl">
      <batComentList title="全部成员"
        placeholder="搜索成员"
        @input="input"
        @handleClick="handleClick"
        @vanSwipeClick="vanSwipeClick"
        @fileListAdd="fileListAdd"
        :vanSwipeList="vanSwipeList"
        :noDataInfo="noDataInfo"
        :fileList="fileList"
        ></batComentList>
  </div>
</template>

<script>
  import batComentList from './batComentList.vue'
  export default {
    name: 'circleCygl', // 成员管理
    components: {
      batComentList,
    },
    data() {
      return {
          vanSwipeList:[
            {
              id: 1,
              title: '极氪员工-李三',
              isCheck: true,
              role: 0, // 0 圈主 1 管理员 2 普通
              header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
            },
            {
              id: 2,
              title: '极氪员工-李四',
              isCheck: false,
              role: 1,
              header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
            },
            {
              id: 3,
              title: '极氪员工-王五',
              isCheck: false,
              role: 2,
              NoMessage: true,
              header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
            },
            {
              id: 4,
              title: '极氪员工-王五',
              isCheck: false,
              header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
            },
            {
              id: 5,
              title: '极氪员工-王五',
              isCheck: false,
              header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
            },
          ],
          noDataInfo: {
            title1: '暂无相关人员'
          },
          fileList: [
            {
              url: 'https://img01.yzcdn.cn/vant/cat.jpeg'
            }
          ]
      }
    },
    methods: {
      // 回调输入框change
      input(v) {
        console.log(v)
      },
      // 回调输入框click
      handleClick(v) {
        console.log(v)
      },
      // 回调数据
      vanSwipeClick(item) {
         this.vanSwipeList.map((v) => {
          if (item.id === v.id) {
            v.isCheck = !v.isCheck;

          }
        })
      },
      // 回调添加
      fileListAdd() {

      }
    }
  }
</script>

<style scoped>

</style>
