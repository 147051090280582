<template>
  <div class="batComentList_main">
    <pageHead class="messageMainView_head" :title="title" />
    <van-row class="search_box">
      <van-col span="20">
        <circleSearchs :placeholder="placeholder" @click="handleClick" @input="input" :disabled="false"></circleSearchs>
      </van-col>
      <van-col span="2">
        <div class="cancal" @click.prevent="cancal"><span class="cancal_title">取消</span></div>
      </van-col>
    </van-row>
    <!-- 列表 -->
    <div class="vant_List" v-if="vanSwipeList.length > 0">
      <van-swipe-cell v-for="(item) in vanSwipeList" :key="item.id" @click.native.prevent="vanSwipeClick(item)">
        <van-card
          :title="item.title"
          class="goods-card"
          :thumb="item.header"
        />
        <span class="vant-tag"
          v-if="item.role === 0 || item.role === 1">{{ item.role === 0 ? '圈主' : (item.role === 1 ? '管理员' : '') }}</span>
        <span class="vant-jy_icon"></span>
      </van-swipe-cell>
    </div>
    <!-- 无数据展示 -->
    <template v-else>
      <div class="hotCircle_noData">
        <div class="noData_box">
          <div class="noData_img"></div>
          <span>{{ noDataInfo.title1 || '暂未数据' }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import circleSearchs from '@/components/searchBox'

export default {
  name: 'batComentList', // 成员列表
  components: {
    pageHead,
    circleSearchs
  },
  props: {
    title: { // 标题
      type: String,
      default: '标题'
    },
    placeholder: {
      type: String,
      default: ''
    },
    vanSwipeList: { // 人员列表
      type: Array,
      default: () => []
    },
    noDataInfo: { // 没有数据
      type: Object,
      default: () => {
      }
    },
    conInfo: { // 确认按钮
      type: String,
      default: '确认添加'
    },
    btnColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      vanSwipeIn: [],
      number: '',
    }
  },
  methods: {
    // 取消
    cancal() {
      this.$router.back()
    },
    // 输入框
    input(v) {
      this.$emit('input', v)
    },
    // 输入框点击
    handleClick(v) {
      this.$emit('handleClick', v)
    },
    // 多选
    vanSwipeClick(item) {
      this.$emit('vanSwipeClick', item)
    },
    // 添加
    fileListAdd() {
      this.$emit('fileListAdd')
    }
  }
}
</script>

<style lang="scss" scoped>
.batComentList_main {
  background: #fff;
  height: 100%;

  .van_cell_box {
    ::v-deep .van-cell {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f4f4f4;
      font-size: 16px;
      color: #000000;
    }
  }

  .uploader_box {
    padding: 18px 15px 0 15px;

    .submitAdd {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #f6f6f6;
      border-radius: 100px;
      font-size: 13px;
      text-align: CENTER;
      color: #6d7278;
      line-height: 35px;
      float: right;
    }

    .submitAdd2 {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #FE5800;
      border-radius: 100px;
      font-size: 13px;
      text-align: CENTER;
      color: #fff;
      line-height: 35px;
      float: right;
    }

    ::v-deep {
      .van-uploader__upload {
        display: none;
      }

      .van-uploader__preview-image {
        width: 36px;
        height: 36px;
        // width: 44px;
        // height: 44px;
      }

      .van-image__img {
        border-radius: 50%;
      }

      .van-uploader__preview-delete {
        border-radius: 50%;
        width: 14px;

        .van-uploader__preview-delete-icon {
          top: -3px;
        }

        .van-icon-cross::before {
          content: '一';
        }
      }
    }

  }

  .search_box {
    padding-top: 12px;

    ::v-deep {
      .van-search__content {
        height: 41px;
        padding-left: 15px;
      }

      .van-cell {
        line-height: 31px;
      }
    }
  }

  ::v-deep .van-search__content {
    border-radius: 70px;
  }

  .cancal {
    position: relative;
    left: 10px;
    top: 3px;

    .cancal_title {
      font-size: 14px;
      text-align: center;
      color: #000000;
      line-height: 14px;
    }
  }

  .vant_List {
    margin-top: 16px;
    height: 540px;
    overflow: scroll;

    ::v-deep {
      .van-swipe-cell__wrapper {
        display: flex;
        border-bottom: 1px solid #f4f4f4;
      }

      .van-card__title {
        padding-left: 0;
      }

      .van-card {
        padding-left: 10px;
        padding: 0 0 0 10px;
      }

      .van-card:not(:first-child) {
        margin-top: 0;
      }

      .van-swipe-cell:first-child {
        margin-top: 0;
      }

      .van-swipe-cell {
        margin-top: 16px;
        padding: 0 15px;
      }
    }

    .checkbox_icon {
      width: 21px;
      height: 21px;
      position: relative;
      top: 10px;
      box-sizing: border-box;
      display: inline-block;
      opacity: 0.9;
      border-radius: 50%;
      border: 2px solid #dcdcdc;
    }

    .active_icon {
      width: 21px;
      height: 21px;
      position: relative;
      top: 10px;
      box-sizing: border-box;
      display: inline-block;
      background: url('~@/assets/imgs/circle/Slice43@2x.png');
      background-size: 100%;
    }
  }

  .hotCircle_noData {
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .noData_box {
      margin-bottom: 16px;

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      font-size: 12px;
      color: #6d7278;
    }
  }

  .vant-tag {
    height: 10px;
    margin-top: 13px;
    margin-left: 8.25px;
    display: inline-block;
    border: 1px solid rgba(254, 88, 0, 0.60);
    border-radius: 1px;
    padding: 2px 6px;
    font-size: 10px;
    color: #fe5800;
    line-height: 10px;
  }

  .vant-jy_icon {

  }
}
</style>
