import { render, staticRenderFns } from "./batComentList.vue?vue&type=template&id=c8ca5f9a&scoped=true&"
import script from "./batComentList.vue?vue&type=script&lang=js&"
export * from "./batComentList.vue?vue&type=script&lang=js&"
import style0 from "./batComentList.vue?vue&type=style&index=0&id=c8ca5f9a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8ca5f9a",
  null
  
)

export default component.exports